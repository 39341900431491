import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useOTP } from "./OTPContext";
import axios from "axios";

const OTPValidation = () => {
  const [otpInput, setOtpInput] = useState(["", "", "", "", "", ""]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { otp } = useOTP();
  const navigate = useNavigate();
  const location = useLocation();
  const formData = location.state;
  console.log("formData", formData);

  const handleOtpChange = (value, index) => {
    const newOtpInput = [...otpInput];
    newOtpInput[index] = value.slice(-1);
    setOtpInput(newOtpInput);

    if (value && index < otpInput.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otpInput[index] && index > 0) {
      const previousInput = document.getElementById(`otp-${index - 1}`);
      if (previousInput) previousInput.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const enteredOtp = otpInput.join("");
    if (enteredOtp === String(otp)) {
      try {
        const registerResponse = await axios.post("/api/createCompany", {
          company_name: formData.companyName,
          company_mobile: formData.phoneNumber,
          company_email: formData.email,
          company_owner: formData.company_owner,
          subdomain: formData.subdomain,
          company_password: formData.password,
          business_type: formData.businessType,
        });

        const subscriptionResponse = await axios.post("/api/createSubscription", {
          title: formData.planName,
          plan: formData.planType,
          company_email: formData.email,
          price: formData.planPrice,
          subdomain: formData.subdomain,
        });

        const registerdata = registerResponse.data;
        const subscriptiondata = subscriptionResponse.data;
        if (registerdata.success && subscriptiondata.success) {
          navigate("/success");
        } else {
          setErrorMessage("Registration failed. Please try again.");
        }
      } catch (error) {
        console.error("Error registering company", error);
        setErrorMessage("Something went wrong. Please try again.");
      }
    } else {
      setErrorMessage("Invalid OTP. Please try again.");
    }
    setIsSubmitting(false);
  };

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="mt-3 mb-3">
          <p className="h2 text-center">Enter OTP</p>
        </div>

        <div className="opt-validation-container">
          <form onSubmit={handleSubmit}>
            <div className="mb-3 text-center">
              <label className="form-label">
                Please enter OTP sent to your email{" "}
                <span className="fw-semibold">{`${formData.email}`}</span>
              </label>
              <div className="d-flex justify-content-center gap-2">
                {otpInput.map((value, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    className="otp-box text-center"
                    value={value}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    maxLength="1"
                    required
                  />
                ))}
              </div>
            </div>

            {errorMessage && (
              <p className="error-message text-center">{errorMessage}</p>
            )}

            <div className="text-center">
              <button
                type="submit"
                className="btn-primary w-100"
                disabled={isSubmitting}
              >
                Validate OTP
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OTPValidation;
