import React, { createContext, useContext, useState } from "react";

const OTPContext = createContext();

export const useOTP = () => {
  return useContext(OTPContext);
};

export const OTPProvider = ({ children }) => {
  const [otp, setOtp] = useState(null);

  return (
    <OTPContext.Provider value={{ otp, setOtp }}>
      {children}
    </OTPContext.Provider>
  );
};
