import React from "react";

const SuccessPage = () => {
  return (
    <div className="container-fluid">
      <div className="container">
        <h1 className="text-center">
          Thanks for registering with us! We will reach out to you after your
          account is activated.
        </h1>
      </div>
    </div>
  );
};

export default SuccessPage;
