const translations = {
  fr: {
    title: "Système Intelligent de Gestion d'Entreprise",
    monthlyTab: "Facturation Mensuelle",
    yearlyTab: "Facturation Annuelle",
    starter: "ESSENTIEL",
    standard: "ÉVOLUTION",
    premium: "ÉLITE",
    price: "€",
    getStarted: "Démarrez un essai gratuit",
    checkItems: [
      [
        { feature: "01 Succursale (Magasin/boutique)", hasFeature: true },
        { feature: "01 caisse", hasFeature: true },
        { feature: "Vente (Produit/Service)", hasFeature: true },
        { feature: "Devis et Facturation", hasFeature: true },
        { feature: "Finances et Pré-comptabilité", hasFeature: true },
        { feature: "Facture normalisée (En option)", hasFeature: true },
        { feature: "Stock, Achat et Inventaire", hasFeature: true },
        { feature: "Relation Client - CRM", hasFeature: true },
        { feature: "Outils e-Marketing", hasFeature: true },
        { feature: "Génération de Code-barres", hasFeature: true },
        { feature: "Rapports et Statistiques", hasFeature: true },
        { feature: "Indicateurs de performance", hasFeature: true },
        { feature: "Workflow (Gestion de Projet)", hasFeature: false },
        { feature: "Ressources Humaines et Paie", hasFeature: false },
        { feature: "e-Commerce (En option)", hasFeature: true },
        { feature: "Application de livraison (En option)", hasFeature: true },
        { feature: "Module complémentaire (Au besoin)", hasFeature: true },
        { feature: "AI Assistant aux métiers", hasFeature: false },
        { feature: "Bot AI", hasFeature: false },
        { feature: "Support Ticket", hasFeature: true },
        { feature: "Support Live Chat", hasFeature: true },
      ],
      [
        { feature: "03 Succursales (Magasin/boutique)", hasFeature: true },
        { feature: "09 caisses", hasFeature: true },
        { feature: "Vente (Produit/Service)", hasFeature: true },
        { feature: "Devis et Facturation", hasFeature: true },
        { feature: "Finances et Pré-comptabilité", hasFeature: true },
        { feature: "Facture normalisée (En option)", hasFeature: true },
        { feature: "Stock, Achat et Inventaire", hasFeature: true },
        { feature: "Relation Client - CRM", hasFeature: true },
        { feature: "Outils e-Marketing", hasFeature: true },
        { feature: "Génération de Code-barres", hasFeature: true },
        { feature: "Rapports et Statistiques", hasFeature: true },
        { feature: "Indicateurs de performance", hasFeature: true },
        { feature: "Workflow (Gestion de Projet)", hasFeature: true },
        { feature: "Ressources Humaines et Paie", hasFeature: true },
        { feature: "e-Commerce (En option)", hasFeature: true },
        { feature: "Application de livraison (En option)", hasFeature: true },
        { feature: "Module complémentaire (Au besoin)", hasFeature: true },
        { feature: "AI Assistant aux métiers", hasFeature: true },
        { feature: "Bot AI", hasFeature: true },
        { feature: "Support Ticket", hasFeature: true },
        { feature: "Support Live Chat", hasFeature: true },
      ],
      [
        { feature: "Nombre de Succursales illimité", hasFeature: true },
        { feature: "Nombre de caisses illimité", hasFeature: true },
        { feature: "Vente (Produit/Service)", hasFeature: true },
        { feature: "Devis et Facturation", hasFeature: true },
        { feature: "Finances et Pré-comptabilité", hasFeature: true },
        { feature: "Facture normalisée (En option)", hasFeature: true },
        { feature: "Stock, Achat et Inventaire", hasFeature: true },
        { feature: "Relation Client - CRM", hasFeature: true },
        { feature: "Outils e-Marketing", hasFeature: true },
        { feature: "Génération de Code-barres", hasFeature: true },
        { feature: "Rapports et Statistiques", hasFeature: true },
        { feature: "Indicateurs de performance", hasFeature: true },
        { feature: "Workflow (Gestion de Projet)", hasFeature: true },
        { feature: "Ressources Humaines et Paie", hasFeature: true },
        { feature: "e-Commerce (En option)", hasFeature: true },
        { feature: "Application de livraison (En option)", hasFeature: true },
        { feature: "Module complémentaire (Au besoin)", hasFeature: true },
        { feature: "AI Assistant aux métiers", hasFeature: true },
        { feature: "Bot AI", hasFeature: true },
        { feature: "Support Ticket", hasFeature: true },
        { feature: "Support Live Chat", hasFeature: true },
      ],
    ],
    mois: "mois",
    popularBadge: "Le Plus Populaire",
  },
  en: {
    title: "Choose Your Perfect Plans",
    monthlyTab: "Monthly Billing",
    yearlyTab: "Yearly Billing",
    starter: "ESSENTIAL",
    standard: "ÉVOLUTION",
    premium: "ÉLITE",
    price: "$",
    getStarted: "Start a free trial",
    checkItems: [
      [
        { feature: "03 Branches (Store/boutique)", hasFeature: true },
        { feature: "09 Cash registers (Cashiers)", hasFeature: true },
        { feature: "Sales (Product/Service)", hasFeature: true },
        { feature: "Quote and Billing", hasFeature: true },
        { feature: "Finance and Pre-Accounting", hasFeature: true },
        { feature: "Standardized invoice (Optional)", hasFeature: true },
        { feature: "Stock, Purchase and Inventory", hasFeature: true },
        { feature: "Customer Relations - CRM", hasFeature: true },
        { feature: "e-Marketing tools", hasFeature: true },
        { feature: "Barcode generation", hasFeature: true },
        { feature: "Reports and Statistics", hasFeature: true },
        { feature: "Performance indicators", hasFeature: true },
        { feature: "Workflow (Project Management)", hasFeature: false },
        { feature: "Human Resources and Payroll", hasFeature: false },
        { feature: "e-Commerce (Optional)", hasFeature: true },
        { feature: "Delivery app (Optional)", hasFeature: true },
        { feature: "Add-on (As needed)", hasFeature: true },
        { feature: "AI Job Assistant", hasFeature: false },
        { feature: "AI bot", hasFeature: false },
        { feature: "Support Ticket", hasFeature: true },
        { feature: "Live Chat Support", hasFeature: true },
      ],
      [
        { feature: "03 Branches (Store/boutique)", hasFeature: true },
        { feature: "09 Cash registers (Cashiers)", hasFeature: true },
        { feature: "Sales (Product/Service)", hasFeature: true },
        { feature: "Quote and Billing", hasFeature: true },
        { feature: "Finance and Pre-Accounting", hasFeature: true },
        { feature: "Standardized invoice (Optional)", hasFeature: true },
        { feature: "Stock, Purchase and Inventory", hasFeature: true },
        { feature: "Customer Relations - CRM", hasFeature: true },
        { feature: "e-Marketing tools", hasFeature: true },
        { feature: "Barcode generation", hasFeature: true },
        { feature: "Reports and Statistics", hasFeature: true },
        { feature: "Performance indicators", hasFeature: true },
        { feature: "Workflow (Project Management)", hasFeature: true },
        { feature: "Human Resources and Payroll", hasFeature: true },
        { feature: "e-Commerce (Optional)", hasFeature: true },
        { feature: "Delivery app (Optional)", hasFeature: true },
        { feature: "Add-on (As needed)", hasFeature: true },
        { feature: "AI Job Assistant", hasFeature: true },
        { feature: "AI bot", hasFeature: true },
        { feature: "Support Ticket", hasFeature: true },
        { feature: "Live Chat Support", hasFeature: true },
      ],
      [
        { feature: "03 Branches (Store/boutique)", hasFeature: true },
        { feature: "09 Cash registers (Cashiers)", hasFeature: true },
        { feature: "Sales (Product/Service)", hasFeature: true },
        { feature: "Quote and Billing", hasFeature: true },
        { feature: "Finance and Pre-Accounting", hasFeature: true },
        { feature: "Standardized invoice (Optional)", hasFeature: true },
        { feature: "Stock, Purchase and Inventory", hasFeature: true },
        { feature: "Customer Relations - CRM", hasFeature: true },
        { feature: "e-Marketing tools", hasFeature: true },
        { feature: "Barcode generation", hasFeature: true },
        { feature: "Reports and Statistics", hasFeature: true },
        { feature: "Performance indicators", hasFeature: true },
        { feature: "Workflow (Project Management)", hasFeature: true },
        { feature: "Human Resources and Payroll", hasFeature: true },
        { feature: "e-Commerce (Optional)", hasFeature: true },
        { feature: "Delivery app (Optional)", hasFeature: true },
        { feature: "Add-on (As needed)", hasFeature: true },
        { feature: "AI Job Assistant", hasFeature: true },
        { feature: "AI bot", hasFeature: true },
        { feature: "Support Ticket", hasFeature: true },
        { feature: "Live Chat Support", hasFeature: true },
      ],
    ],
    mois: "months",
    popularBadge: "Most Popular",
  },
};

export default translations;
